import setup_fancy_hover from "../../components/grid-tile-hover/main.js";

window.kutAjaxHook = function kutAjaxHook() {
  van11yAccessibleTabPanelAria &&
    van11yAccessibleTabPanelAria(document.getElementById("genesis-content"));

  window.modal = new window.VanillaModal();

  jQuery(".js-copy-clip").click(function () {
    // ok if it doesn't work in IE.
    // eslint-disable-next-line compat/compat
    navigator.clipboard && navigator.clipboard.writeText(window.location);
    
    $(".js-copy-clip__confirmation").removeClass( "hide-confirmation" );

    setTimeout(function(){ 
      $(".js-copy-clip__confirmation").addClass( "hide-confirmation" );
    }, 1000);
  });

  jQuery(".show-hero__expanded").addClass("show-hero--show");
  jQuery(".show-hero__toggle").click(function () {
    jQuery(".show-hero__expanded").toggleClass("show-hero--show");
    jQuery(".show-hero__colapsed").toggleClass("show-hero--show");
    var id = jQuery(".show-hero__toggle").attr("href");
    jQuery(id).toggleClass("show-details--show");
  });

  jQuery(".search-filter__expanded").addClass("show-hero--show");
  jQuery(".search-fliter__toggle").click(function () {
    jQuery(".search-filter__expanded").toggleClass("show-hero--show");
    jQuery(".search-filter__colapsed").toggleClass("show-hero--show");
  });

  if (window.googletag) {
    window.googletag.cmd.push(function () {
      window.googletag.display("div-gpt-ad-1598924621436-0");
    });
    window.googletag.cmd.push(function () {
      window.googletag.display("div-gpt-ad-1598924895426-0");
    });
  }

  window.__sharethis__ && window.__sharethis__.initialize();

  setup_fancy_hover(".new-podcast-container__grid-container__podcast-show");

  $( ".search-filter__title" ).keypress(function(e) {
    if (e.which === 32 || e.which === 13) {
      if ($(".search-filter__list").css('display') == 'flex') {
        $(".search-filter__list").css('display', "none");
        $(".search-filter__expanded").addClass( "show-hero--show" );
        $(".search-filter__colapsed").removeClass( "show-hero--show" );
      } else {
        $(".search-filter__list").css('display', "flex");
        $(".search-filter__colapsed").addClass( "show-hero--show" );
        $(".search-filter__expanded").removeClass( "show-hero--show" );
      }
    }
  });

  $(".search-filter__title").on("click", function(){
    if ($(".search-filter__list").css('display') == 'flex') {
      $(".search-filter__list").css('display', "none");
      $(".search-filter__expanded").addClass( "show-hero--show" );
      $(".search-filter__colapsed").removeClass( "show-hero--show" );
    } else {
      $(".search-filter__list").css('display', "flex");
      $(".search-filter__colapsed").addClass( "show-hero--show" );
      $(".search-filter__expanded").removeClass( "show-hero--show" );
    }
  });

  window.googletag = window.googletag || {cmd: []};
	googletag.cmd.push(function() {
		googletag.defineSlot('/41259720/KUT-X_Studio_728x90', [728, 90], 'div-gpt-ad-1598924621436-0').addService(googletag.pubads());
   		googletag.defineSlot('/41259720/KUT-X_Studio_320x50', [320, 50], 'div-gpt-ad-1598924895426-0').addService(googletag.pubads());
		googletag.pubads().enableSingleRequest();
		// googletag.pubads().disableInitialLoad();
		googletag.pubads().enableLazyLoad();
    googletag.enableServices();
    
    googletag.cmd.push(function() {
      // This will only register the slot.
      // Ad will be fetched only when refresh is called.
      googletag.display('div-gpt-ad-1598924895426-0');
    });

    googletag.cmd.push(function() {
      // This will only register the slot.
      // Ad will be fetched only when refresh is called.
      googletag.display('div-gpt-ad-1598924621436-0');
    });
  });

  if (typeof(pSUPERFLY) !== 'undefined') {
    pSUPERFLY.virtualPage({
      title: document.title
    });
  }
};

window.processDataa = function (dataa) {
  var bodyTag = /<body(.*?)>/gim;
  var findClass = /(?:class)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;
  var body = dataa.match(bodyTag);
  var classes = body && body.length === 1 && findClass.exec(body[0]);
  if (classes.length === 2) {
    var classStr = classes[1];
    if (classStr) {
      var body = document.getElementsByTagName("body")[0];
      var playerRunning = body.className.indexOf("kut_player_shown") > -1;
      body.className = classStr + (playerRunning && " kut_player_shown");
    }
  }

  var links = /<link(.*?)>/gim;
  var findType = /(?:type)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;
  var findHref = /(?:href)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim;
  var parser = new DOMParser();
  var test = dataa.match(links).filter(function (i) {
    var type = i.match(findType);
    if (!type) return false;
    if (type[0].indexOf('text/css') < 0) return false;
    var href = i.match(findHref);
    if (!href) return false;
    return true;
  });
  var activeStylesheets = [...document.styleSheets].map(function (s) {
    return s.href;
  });
  var tt = test.map(function(i) {
    var node = parser.parseFromString(i, "text/xml");
    return node.firstChild.attributes.href.value.toString();
  });
  var arg = tt.filter(function (href) {
    return activeStylesheets.indexOf(href) < 0;
  });
  for(var i=arg.length; i--; ) {
    var href = arg[i];
    var link = document.createElement("link");
    link.href = href;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);
  }
};

(function () {
  "use strict";
  jQuery("html").removeClass("no-js");

  var { default: VanillaModal } = require("vanilla-modal");

  window.VanillaModal = VanillaModal;
  window.modal = new window.VanillaModal();
  kutAjaxHook();
})();
