
// DOM traversing polyfill
(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
})();

// Helper functions
(function () {
  const getObjectFromHash = function (hash) {
    const params = hash.split("&");
    let result = {},
      key,
      value;
    params.forEach((param) => {
      [key, value] = param.split("=");
      result[key] = value;
    });
    return result;
  };

  const removeElementsFromObject = function (object, keys) {
    keys.forEach((key) => {
      if (object[key]) {
        delete object[key];
      }
    });
    return object;
  };

  const handleContentMinHeight = function (event) {
    const content = document.querySelector(".content-sidebar-wrap");
    const sections = document.querySelectorAll(".site-container > *");

    let i = 0,
      height = window.innerHeight;
    do {
      height -= sections[i].offsetHeight;
      i++;
    } while (content != sections[i]);

    content.style.minHeight = height + "px";
  };

  window.Helper = {
    getObjectFromHash: getObjectFromHash,
    removeElementsFromObject: removeElementsFromObject,
    handleContentMinHeight: handleContentMinHeight,
  };
})();

//
// set min-height to content's container to avoid the
// footer to jump when content is loaded with AJAX
//
["load", "resize"].forEach((eventType) => {
  window.addEventListener(eventType, Helper.handleContentMinHeight);
});

//
// handle header's player URI when is ready
//
window.addEventListener("DOMContentLoaded", function () {
  // get initial params from header's player
  const iframe = document.querySelector(".kut_player");
  const hash = iframe.src.substr(iframe.src.indexOf("#") + 1);
  const params = Helper.getObjectFromHash(hash);

  // save params to use later
  iframe.dataset.params = JSON.stringify(params);
});

//
// handle click in content's players
//
document.addEventListener("click", function (event) {
  const player = event.target.closest(".kut_player_small");
  if (!player) return;

  // event is triggered from an element that match the player's class
  event.preventDefault();

  if (document.body.className.indexOf("kut_player_shown") === -1) {
    document.body.classList.add("kut_player_shown");
  }

  // add some feedback
  player.classList.add("loading");
  setTimeout(function (e) {
    player.classList.replace("loading", "playing");
  }, 500);

  const iframe = document.querySelector(".kut_player");
  // get params from player and iframe
  const player_params = JSON.parse(player.dataset.params);
  const initial_params = JSON.parse(iframe.dataset.params || "{}");

  // merge player params over initial params
  let params = { ...player_params, ...initial_params };

  // remove excluded params
  params = Helper.removeElementsFromObject(params, ["list"]);

  params["autoplay"] = "true";

  // append new hash to iframe based on result params
  let src = iframe.src.substr(0, iframe.src.indexOf("#")),
    glue = "#";
  Object.keys(params).forEach((key) => {
    src += glue + key + "=" + encodeURI(params[key]);
    glue = "&";
  });

  // change iframe's URI
  iframe.src = src;
});
